import { updateJobIndexCheck } from '@components/common';
import {
  addJobsCountTraits,
  jobSearchResultTrackEvent,
} from '@components/common/event-tracker';
import { Icon, SaveJobIcon } from '@components/jobCard/styled';
import { Button } from '@license-admin/boldfjcomponents';
import useMediaQuery from '@utils/useMediaQuery';
import { saveJobApi } from 'API/jobs';
import { isRCPortal } from 'helper';
import { getSearchType, getViewType } from 'helper/eventTrackingUtils';
import {
  Action,
  ClickOption,
  USER_TRAITS_JOBS_COUNT,
} from 'helper/eventTrackingUtils/constant';
import { useRouter } from 'next/router';

export default function SaveJobComponent(props: any) {
  const router = useRouter();

  const showIcon = props.showIcon;

  const isMobileView = useMediaQuery('(max-width:991px)');
  const savedJob =
    props.savedJobsArray && props.savedJobsArray?.includes(props.jobId);

  const markedSaveJob = (
    e: React.MouseEvent<HTMLElement>,
    jobId: string,
    status: boolean
  ) => {
    e.stopPropagation();
    e.preventDefault();
    saveJobApi(jobId, status);
    addJobsCountTraits(USER_TRAITS_JOBS_COUNT.SAVED_JOBS, status ? 1 : -1);

    const mergerdQueries = {
      ...props?.queryParams,
      ...router.query,
    };
    if (status) {
      props.setSavedJobsArray([...props.savedJobsArray, jobId]);
      jobSearchResultTrackEvent(
        Action.Clicked,
        getSearchType(),
        getViewType(props.isHostedJob),
        props.jobDetails,
        !props.isHostedJob && props.jobsData,
        mergerdQueries,
        ClickOption.Save_Job,
        null,
        updateJobIndexCheck(props.customAttribute, props.jobNumber),
        props.guid
      );
    } else {
      props.setSavedJobsArray(
        props.savedJobsArray.filter((id: string) => id !== jobId)
      );
      jobSearchResultTrackEvent(
        Action.Clicked,
        getSearchType(),
        getViewType(props.isHostedJob),
        props.jobDetails,
        !props.isHostedPage && props.jobsData,
        props.queryParams ? props.queryParams : null,
        ClickOption.Unsave_Job,
        null,
        updateJobIndexCheck(props.customAttribute, props.jobNumber),
        props.guid
      );
    }
  };

  return (
    <>
      {showIcon ? (
        <SaveJobIcon id={`job-save-` + props.jobId}>
          <Icon
            isRCPortal={isRCPortal}
            className={`fa ${savedJob ? 'fa-solid' : 'fa-regular'} fa-heart`}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              markedSaveJob(e, props.jobId, !savedJob)
            }
            aria-label={savedJob ? 'UnSave Job' : 'Save Job'}
          />
        </SaveJobIcon>
      ) : (
        <Button
          buttonType={
            props.saveJobBtnType
              ? props.saveJobBtnType
              : props.isDetailPage
              ? 'secondary'
              : 'quaternary'
          }
          icon={
            isRCPortal
              ? ``
              : `fa ${savedJob ? 'fa-solid' : 'fa-regular'} fa-heart`
          }
          text={savedJob ? 'Unsave Job' : 'Save Job'}
          clickHandler={(e: React.MouseEvent<HTMLElement>) =>
            markedSaveJob(e, props.jobId, !savedJob)
          }
          buttonSize={
            props.mediumButton && !props.isHostedPage
              ? 'md'
              : isMobileView
              ? 'md'
              : 'lg'
          }
          underlineOnHover
          id={`job-savebtn-` + props.jobId}
          ButtonClass="save-job-btn"
        />
      )}
    </>
  );
}
