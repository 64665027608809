import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

interface StyleProps {
  isActive?: boolean;
  featured?: boolean;
  blurTest?: boolean;
  isNoImage?: boolean;
  noSpace?: boolean;
  isVariant?: boolean;
  isBadgeSuccess?: boolean;
  isRCPortal?: boolean;
}

export const Wrapper = styled.div<StyleProps>`
  cursor: pointer;
  ${device.mobileLg} {
    .company-logos {
      max-height: 2.5rem;
      object-fit: contain;
      width: 100%;
      object-position: left;
      margin-bottom: 1rem;
    }
  }
  ${({ theme: { JobCardStyle }, isActive, isRCPortal }) => {
    const commonStyles = css`
      border-radius: ${isRCPortal ? '0.4rem' : '0.8rem'};
      margin-bottom: ${isRCPortal ? '0.8rem' : '1.4rem'};
      padding: ${isRCPortal ? '2.4rem' : '1.6rem 2rem 1rem'};

      ${device.mobileLg} {
        padding: ${isRCPortal ? '2.4rem' : '1.6rem 2rem'};
      }
    `;

    return css`
      ${commonStyles}

      ${isActive
        ? css`
            border: 0.2rem solid ${JobCardStyle.BdrColors.wrapperInActive};
            border-left-color: ${JobCardStyle.BdrColors.wrapperInActive};
            color: ${JobCardStyle.Colors.wrapper};
          `
        : css`
            border: 0.2rem solid ${JobCardStyle.BdrColors.wrapper};
            &:hover {
              cursor: pointer;
            }
          `}
    `;
  }}

  ${({ theme: { JobCardStyle }, featured, isActive }) =>
    JobCardStyle &&
    featured &&
    !isActive &&
    css`
      border-left: 0.3rem solid ${JobCardStyle.BdrColors.wrapperFeatured};
    `}      
  cursor: ${(isPastJob) => !isPastJob && 'pointer'};
  position: relative;
  ${(noSpace) =>
    noSpace &&
    css`
      .right {
        padding: 0;
      }
    `}

  ${({ theme: { JobCardStyle }, isVariant }) =>
    JobCardStyle &&
    isVariant &&
    css`
      ${device.mobileLg} {
        border: 0;
        border-top: 0.5px solid ${JobCardStyle.BdrColors.wrapper};
        margin: 0 -1.5rem;
        border-radius: 0;
      }
    `}
`;

export const JobsDetailsWrap = styled.div`
  padding-right: 0.8rem;
`;
export const HeadWrap = styled.div`
  ${Mixin.HorizontalCenter({ justify: 'space-between' })};
`;

export const MiddleWrap = styled.div<{
  isBadgeSuccess?: boolean;
  isPremiumUser?: boolean;
}>`
  ${Mixin.VerticalCenter()};
  margin-bottom: ${({ isBadgeSuccess, isPremiumUser }) =>
    isBadgeSuccess && !isPremiumUser
      ? '1.6rem'
      : `${isPremiumUser ? '0' : '0.4rem'}`};
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle.FontSizes.middleWrap};
    `}
`;

export const Paragraph = styled.p<{ isMiniSummary?: boolean }>`
  ${({ theme: { JobCardStyle }, isMiniSummary }) =>
    isMiniSummary &&
    css`
      ${Mixin.Ellipsis()};
    `}
  ${({ theme: { JobCardStyle } }) =>
    css`
      color: ${JobCardStyle.Colors.jobParagraph};
    `}
  margin: 0;
`;

export const IconWraps = styled.span<{ leftSpace?: boolean }>`
  display: inline-flex;
  align-items: center;
  margin: 0 0.8rem 0 0.8rem;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.iconWrap};
    `}
  ${device.mobileLg} {
    margin: 0 0.8rem 0 0;
    ${({ leftSpace }) =>
      leftSpace &&
      css`
        margin: 0 0 0 0.5rem;
      `}
  }
`;

export const TagsWrap = styled.ul<{ wrapTag?: any; isPremiumUser?: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  ${Mixin.VerticalCenter({ align: 'baseline' })};
  flex-wrap: ${({ wrapTag }) => (wrapTag ? 'wrap' : 'nowrap')};

  ${device.mobileLg} {
    //flex-wrap: ${({ isPremiumUser }) => (isPremiumUser ? 'wrap' : 'nowrap')};
    flex-wrap: wrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ShortDescription = styled.p`
  ${Mixin.Ellipsis({ row: 2 })};
  margin: 0;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      ${device.mobileLg} {
        ${Mixin.Ellipsis({ row: 2 })};
        font-size: ${JobCardStyle.FontSizes.shortDescription};
      }
    `}
`;

export const TagListWrap = styled.ul`
  padding: 0;
  margin: 0;
`;
export const TagList = styled.li<{
  isTagName?: boolean;
  isPremiumUser?: boolean;
  isLocationFlag?: boolean;
  isVariant?: boolean;
  isBadgeSuccess?: boolean;
}>`
  ${({ isTagName, isBadgeSuccess, isPremiumUser }) =>
    isTagName &&
    css`
      margin: ${isBadgeSuccess ? `0 1rem 1rem 0` : '0 1rem 0.7rem 0'};
      flex-shrink: 0;
      ${({ theme: { JobCardStyle } }) =>
        JobCardStyle &&
        css`
          background: ${JobCardStyle.BgColors.tagName};
          padding: ${isBadgeSuccess ? '.4rem 2.4rem' : '0.5rem 0.8rem'};
          font-size: ${JobCardStyle?.FontSizes?.tagName};
          border-radius: ${isBadgeSuccess ? '9.9rem' : '20vmax'};
          font-weight: ${isBadgeSuccess ? '400' : '500'};
        `}
    `}
  ${({ theme: { JobCardStyle }, isLocationFlag, isBadgeSuccess }) =>
    isLocationFlag &&
    css`
      display: inline-flex;
      align-items: center;
      padding: ${isBadgeSuccess ? '0' : '0.5rem 0.4rem 0.5rem 0'};
      color: ${JobCardStyle.Colors.jobLocations};
      ${isBadgeSuccess &&
      css`
        font-size: ${JobCardStyle?.FontSizes?.jobCardCountry};
      `};
    `}
`;

export const LocationSpan = styled.span<{ isVariant?: boolean }>`
  flex: 1;
  ${Mixin.Ellipsis({ row: 1 })}

  ${({ theme: { JobCardStyle }, isVariant }) =>
    JobCardStyle &&
    css`
      ${device.mobileLg} {
        font-size: ${JobCardStyle?.FontSizes?.locationSpan};
        color: ${isVariant && JobCardStyle.Colors.locationSpan};
      }
    `}
`;

export const BottomWrap = styled.div`
  ${Mixin.VerticalCenter({ gap: '0.4rem' })}

  .accolades-img {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

export const Featured = styled.span<{
  isVariant?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ theme: { JobCardStyle }, isVariant, isRCPortal }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.featured};
      ${device.mobileLg} {
        font-size: ${isVariant && JobCardStyle.FontSizes.featured};
      }
      ${isRCPortal &&
      css`
        .star-icons {
          font-size: 1.8rem;
        }
      `}
    `}
`;

export const Title = styled.a<{ blurTest?: boolean }>`
  line-height: 1.5;
  display: inline;
  ${({ blurTest }) =>
    blurTest &&
    css`
      filter: blur(0.8rem);
    `}

  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle.FontSizes.title};
      font-weight: ${JobCardStyle.FontWeight.title};
      ${device.mobileLg} {
        font-size: ${JobCardStyle.FontSizes.titleSm};
      }
    `}
    
    &:focus {
    text-decoration: underline;
  }
  &::before {
    content: '';
    ${Mixin.Position({ position: 'absolute', value: '0 0 0 0' })}
  }
`;
export const DeleteIcon = styled.span`
  margin-left: auto;
  font-weight: 400;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.deleteIcon};
      font-size: ${JobCardStyle.FontSizes.deleteIcon};
    `}
`;

export const MutedTitle = styled.h5<{ isPastJob?: boolean }>`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle.FontSizes.title};
      font-weight: ${JobCardStyle.FontWeight.title};
      color: ${JobCardStyle.Colors.mutedtext};
    `}
`;
export const TitleWrap = styled.div<{
  isRCPortal?: boolean;
  isPremiumUser?: boolean;
}>`
  flex-grow: 1;
  margin-bottom: ${({ isRCPortal, isPremiumUser }) =>
    isRCPortal ? `${isPremiumUser ? 0 : '1.6rem'}` : '0.6rem'};
  ${device.mobileLg} {
    width: calc(100% - 7.2rem);
  }
`;

export const JobCompany = styled.h3<{
  isVariant?: boolean;
  isRCPortal?: boolean;
}>`
  line-height: 1.5;
  margin: ${({ isRCPortal }) =>
    css`
      ${isRCPortal ? '0.8rem 0 1.6rem' : '0.8rem 0 0.7rem'}
    `};
  ${({ theme: { JobCardStyle }, isVariant }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle?.Colors?.jobCompany};
      font-size: ${JobCardStyle?.FontSizes?.jobCompany};
      font-weight: ${JobCardStyle?.FontWeight?.jobCompany};

      ${device.mobileLg} {
        font-size: ${isVariant && JobCardStyle.FontSizes.jobCompanyVariant};
        margin: ${isVariant && '0.3rem 0 0.4rem'};
      }
    `}
`;

export const ExpiredText = styled.strong<{ isVariant?: boolean }>`
  line-height: 1.5;
  margin-top: 1rem;
  display: block;
  ${({ theme: { JobCardStyle }, isVariant }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle.FontSizes.jobCompany};

      ${device.mobileLg} {
        font-size: ${JobCardStyle.FontSizes.jobCompanyVariant};
        margin-top: 0.5rem;
      }
    `};
`;

export const JobAge = styled.div`
  flex: 0 0 auto;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      > span {
        color: ${JobCardStyle.Colors.jobAgeDays};
      }
      font-size: ${JobCardStyle.FontSizes.jobAge};
      .new-job {
        color: ${JobCardStyle.Colors.jobAge};
        font-weight: ${JobCardStyle.FontWeight.jobAge};
        padding-right: 0.8rem;
      }
    `}
`;

export const NewTag = styled.span<{
  isVariant?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ theme: { JobCardStyle }, isVariant, isRCPortal }) =>
    JobCardStyle &&
    css`
      ${isRCPortal
        ? css`
            background-color: ${JobCardStyle?.BgColors?.jobAge};
            padding: 0.4rem 1rem;
            color: ${JobCardStyle?.Colors?.jobAgeNew};
            margin-left: 0.8rem;
            font-weight: 700;
            font-size: ${JobCardStyle?.FontSizes?.jobAgeNew};
          `
        : css`
            color: ${JobCardStyle.Colors.jobAge};
            font-weight: ${JobCardStyle.FontWeight.jobAge};
            font-size: ${JobCardStyle.FontSizes.jobAge};
            padding-right: 0.8rem;
          `}

      ${device.mobileLg} {
        font-size: ${isVariant && JobCardStyle.FontSizes.newTag};
      }
    `}
  ${({ isRCPortal }) =>
    isRCPortal
      ? Mixin.Position({ position: 'relative', value: `0` })
      : Mixin.Position({ position: 'relative', value: '-0.4rem auto auto' })}
  
  margin-left: 1rem;
`;

export const SaveJobIcon = styled.span`
  display: block;
  text-align: right;
  position: relative;

  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      color: ${JobCardStyle.Colors.saveJob};
    `}
`;

export const TimeSpan = styled.span`
  ${device.mobileLg} {
    font-size: 1rem;
    color: #343a40;
    font-weight: 500;
  }
`;

export const Icon = styled.button<{ isRCPortal?: boolean }>`
  padding: 0;
  ${({ theme: { JobCardStyle }, isRCPortal }) =>
    JobCardStyle &&
    css`
      color: ${isRCPortal
        ? JobCardStyle?.Colors?.saveJobPaidUser
        : JobCardStyle.Colors.saveJob};
      font-size: ${isRCPortal
        ? JobCardStyle?.FontSizes?.saveJobPaidUser
        : null};
    `}
`;
export const WrapContent = styled.div`
  padding: 1.6rem 2rem;
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      border: 0.1rem solid ${JobCardStyle.BdrColors.wrapper};
    `}
`;

export const JobNotesView = styled.div`
  margin-top: 0.5rem;
`;

export const AccoladeImgWrap = styled.div`
  flex-shrink: 0;
  margin-right: 1.2rem;
`;

export const AccoladeImg = styled.div`
  display: inline;
  margin-left: -0.5rem;

  &:first-child {
    margin-left: 0;
  }
`;

export const AccoladeNameWrap = styled.div`
  width: 100%;
  ${Mixin.Ellipsis({ row: 1 })}
`;

export const SubAccoladeNameWrap = styled.div``;

export const AccoladeName = styled.span`
  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      font-size: ${JobCardStyle.FontSizes.accoladeName};
      font-weight: ${JobCardStyle.FontWeight.accoladeName};
    `}
`;

export const MoreAccoladeIcon = styled.span`
  display: inline-block;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  line-height: 2.3rem;
  text-align: center;
  ${Mixin.Position({ position: 'relative', value: '0.4rem auto auto' })}
  margin-left: -0.5rem;
  z-index: -1;

  ${({ theme: { JobCardStyle } }) =>
    JobCardStyle &&
    css`
      background: ${JobCardStyle.BgColors.accIcon};
      color: ${JobCardStyle.Colors.accIcon};
      border: 0.1rem solid ${JobCardStyle.BdrColors.accIcon};
    `}
`;

export const ThreeDots = styled.span`
  ${Mixin.Position({ position: 'relative', value: '-0.4rem auto auto' })}
`;
export const CompnayNameInfoWrap = styled.div`
  ${device.mobileLg} {
    display: flex;
    .textWrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const LocationIconSpan = styled.span`
  margin-left: 0.5rem;
`;
