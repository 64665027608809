import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

const blob_path = process.env.NEXT_PUBLIC_CONTENT_URL;

interface StyledBtnProps {
  primary?: boolean;
  fWeight: string;
  widthNull: boolean;
}

export const BannerUnlockWrap = styled.a<{ isRCPortal?: boolean }>`
  text-decoration: none;
  margin: 3rem 0 1rem;
  position: relative;
  display: block;

  ${device.mobileLg} {
    margin: 3rem 0 1rem;
  }

  &:hover {
    text-decoration: none;
  }

  .signup-btn {
    padding: 1rem;
    margin-bottom: 1.3rem;
  }

  .benefit-ul {
    list-style-type: none;
    padding-left: 0;
    ${({ isRCPortal }) =>
      isRCPortal
        ? css`
            margin: 0 0 2.2rem;
          `
        : css`
            margin: 0 0 1.6rem;
          `}

    li {
      text-decoration: none;
      ${({ isRCPortal }) =>
        isRCPortal
          ? css`
              margin-bottom: 0.8rem;
            `
          : css`
              margin-bottom: 0.4rem;
            `}
      ${({ theme: { JobDetailsStyle }, isRCPortal }) =>
        JobDetailsStyle &&
        css`
          color: ${isRCPortal ? '#212529' : JobDetailsStyle.Colors.list};
          font-size: ${!isRCPortal && '1.4rem'};

          i {
            color: ${JobDetailsStyle.Colors.iconblue};
            font-weight: ${JobDetailsStyle.FontWeight.icon};
            font-size: 1.9rem;
            margin-right: 0.75rem;
          }
        `}
    }
    ${device.mobileTab} {
      padding-left: 1.3rem;
    }
  }
`;

export const CardContent = styled.div<{
  isHostedPage?: boolean;
  isRCPortal?: boolean;
}>`
  ${({ isRCPortal }) =>
    css`
      ${isRCPortal
        ? Mixin.AllCenter({
            align: 'self-start',
            justify: 'space-between',
            direction: 'column',
            wrap: 'wrap',
          })
        : Mixin.VerticalCenter()}
    `};
  .left-section {
    flex-basis: ${({ isHostedPage, isRCPortal }) =>
      isHostedPage || isRCPortal ? '70%' : '55%'};
  }

  margin-bottom: 0.2rem;

  min-width: 39.8rem;
  padding: 2.4rem;
  border-radius: 0.8rem;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      background: ${JobDetailsStyle?.BgColors?.CardContent};
    `}

  ${device.mobileTab} {
    max-width: 100%;
    width: 100%;

    .left-section {
      width: 100%;
      flex-basis: 100%;
    }
  }
`;

export const CardWrapper = styled.div<{
  isRCPortal?: boolean;
  isBgimage?: boolean;
}>`
  max-width: 100%;
  position: relative;
  border-radius: 0.8rem;
  background-position: center;
  background-size: cover;
  ${({ isBgimage }) =>
    isBgimage &&
    css`
      background-image: url(${blob_path}/images/unlock-banner-2.jpg);
    `}
  ${({ theme: { JobDetailsStyle }, isRCPortal }) =>
    JobDetailsStyle &&
    css`
      padding: 2.4rem 16rem 2.4rem 2.4rem;
      margin-bottom: ${isRCPortal ? '4rem' : '3.1rem'};
      box-shadow: 0 0 1rem 0 ${JobDetailsStyle.BoxShadow.card};
      border: 0.1rem solid ${JobDetailsStyle.BdrColors.card};
      filter: ${JobDetailsStyle.GrayScale.CardWrapper};

      &:hover {
        box-shadow: 0.2rem 0.2rem 1.5rem ${JobDetailsStyle.BoxShadow.cardHover};
      }
      .find-you-remote-job {
        ${isRCPortal
          ? css`
              max-width: 29rem;
              margin: 0 auto;
              background-color: ${JobDetailsStyle?.BgColors?.cardButton};
              border-color: ${JobDetailsStyle?.BdrColors?.cardButton};
              font-size: ${JobDetailsStyle?.FontSize?.cardButton};
            `
          : css`
              background-color: ${JobDetailsStyle.BgColors.cardButton};
              border-color: ${JobDetailsStyle.BdrColors.cardButton};
              &:hover {
                background: ${JobDetailsStyle.BgColors.cardHoverButton};
              }
            `}
      }
    `}

  ${device.mobileTab} {
    max-width: 100%;
    padding: 1.3rem 0.9rem;
  }
`;
export const LeftBox = styled.div`
  flex: 1;
`;
export const Title = styled.h3<{ isRCPortal?: boolean }>`
  margin-bottom: 2.2rem;

  .border-underline {
    position: relative;

    &::after {
      display: block;
      height: 1rem;
      content: '';
      ${Mixin.Position({ value: 'auto auto -0.4rem 0' })}
      width: 100%;
    }

    &.visible {
      &::after {
        background: url(${blob_path}/images/orange-border-bold.svg) 0 0
          no-repeat;
      }
    }
  }
  ${({ theme: { JobDetailsStyle }, isRCPortal }) =>
    JobDetailsStyle &&
    css`
      font-size: ${!isRCPortal && JobDetailsStyle.FontSize.unlockBannerTitle};
      //    font-weight: ${isRCPortal ? 500 : null};
      line-height: ${!isRCPortal && '3.5rem'};
    `}
  ${device.mobileTab} {
    line-height: normal;
    font-weight: 600;
    padding-top: 2rem;
    margin-right: -2rem;
    position: relative;
    z-index: 100;

    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.unlockBannerTitleSm};
      `}
  }
`;
export const UnlockIcon = styled.div`
  ${Mixin.Position({
    value: '-6rem auto auto 21.5rem',
  })}
`;

export const LockArrow = styled.div<{ isDetailsPage?: boolean }>`
  ${({ isDetailsPage }) =>
    Mixin.Position({
      value: isDetailsPage
        ? '1.2rem auto auto 2.8rem'
        : '-5.0rem auto auto 24rem',
    })}
  @media screen and (max-width:1199px) {
    ${({ isDetailsPage }) =>
      isDetailsPage &&
      css`
        inset: 1.6rem auto auto 2.4rem;

        img {
          max-height: 3rem;
        }
      `}
  }
`;

export const MobWrapper = styled.div`
  ${device.mobileTab} {
    margin-bottom: 0.5rem;
    position: relative;
    ${Mixin.AllCenter({ align: 'flex-start', justify: 'space-between' })}
  }
`;
export const RightSideImg = styled.div<{
  isHostedPage?: boolean;
  isHybrid?: boolean;
}>`
  display: inline;
  margin-top: -4rem;

  ${({ isHostedPage, isHybrid }) =>
    (isHostedPage || isHybrid) &&
    css`
      margin-top: 0;
    `}
`;

export const MobileImage = styled.img`
  margin: 0;
  min-height: 14.3rem;
`;
export const LinkBtn = styled.button<StyledBtnProps>`
  border: 0;
  line-height: 1.5;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.4rem;
  outline: 0;

  ${({ theme: { JobDetailsStyle }, fWeight, primary, widthNull }) =>
    JobDetailsStyle &&
    css`
      display: ${widthNull || primary ? 'inline-grid' : 'flex'};
      width: ${widthNull || primary ? null : '100%'};
      padding: ${primary ? '0.7rem 2.4rem' : '0.7rem 1.2rem'};
      color: ${JobDetailsStyle.Colors.linkBtn};
      background-color: ${JobDetailsStyle.BgColors.linkBtn};
      font-size: ${JobDetailsStyle.FontSize.linkBtn};
      font-weight: ${fWeight || '700'};
    `}
`;
export const AuthDetails = styled.div`
  margin-top: 0.8rem;
`;

export const BannerQuote = styled.blockquote`
  position: relative;

  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      font-size: ${JobDetailsStyle.FontSize.bannerQuote};
      color: ${JobDetailsStyle.Colors.bannerQuote};
      font-weight: ${JobDetailsStyle.FontWeight.quote};

      .highlight {
        color: ${JobDetailsStyle.Colors.textHighlight};
        font-weight: ${JobDetailsStyle.FontWeight.highlightText};
      }
    `}

  &::before {
    content: '';
    ${Mixin.Position({ value: '0.2rem 2.2rem auto auto' })}
    width: 100%;
    height: 100%;
  }

  &.visible {
    &::before {
      background-image: url(${blob_path}/images/quote-light.svg);
      background-repeat: no-repeat;
    }
  }
`;

export const QuoteWrapper = styled.div`
  ${Mixin.VerticalCenter()};
  margin: 0 0 0 2rem;
`;

export const AuthourText = styled.p`
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      font-size: ${JobDetailsStyle.FontSize.authour};
      color: ${JobDetailsStyle.Colors.authour};
    `}
  margin-bottom:0;
`;

export const AuthourHighlight = styled.span`
  font-weight: 500;
`;

export const VerifiedInfo = styled.strong`
  display: block;
  margin: 0 0 0 auto;
  width: 11.8rem;
  min-height: 2.2rem;
  text-align: center;
  padding-top: 0.2rem;
  line-height: 2.4rem;

  &.visible {
    background: url(${blob_path}/images/icn-verified-member.svg);
  }

  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      font-size: ${JobDetailsStyle.FontSize.verfiedInfo};
      font-weight: ${JobDetailsStyle.FontWeight.verfiedInfo};
      color: ${JobDetailsStyle.Colors.verfiedInfo};
    `}
`;

export const RightImageWrap = styled.div<{
  isHostedPage?: boolean;
  isHybrid?: boolean;
}>`
  position: relative;
  flex-basis: 45%;
  ${({ isHostedPage, isHybrid }) =>
    (!isHostedPage || !isHybrid) &&
    css`
      flex-basis: auto;
    `}
`;

export const SiteJabberDesktop = styled.div`
  ${Mixin.Position({ value: 'auto auto -1rem -3rem' })}
`;

export const QuoteBox = styled.div<{ isHostedPage?: boolean }>`
  ${({ theme: { JobDetailsStyle }, isHostedPage }) =>
    JobDetailsStyle &&
    isHostedPage &&
    css`
      background-color: ${JobDetailsStyle.BgColors.quoteBox};
      padding: 0.8rem 0 0.8rem 2.4rem;
      position: relative;
      margin: 2rem -2.4rem -1.6rem;
    `}
`;
